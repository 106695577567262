import React from "react"

const SWMMLogo = (props) => {
  return (
    <svg
      id="prefix__Livello_1"
      x={0}
      y={0}
      viewBox="0 0 120 120"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#020203}"}</style>
      <circle cx={60} cy={60} r={55} fill="#4da0ae" />
      <path
        className="prefix__st1"
        d="M35.1 43.5c4.7 0 9.2-1.9 12.4-5.2 3.2 3.3 7.7 5.2 12.4 5.2s9.2-1.9 12.4-5.2c3.2 3.3 7.7 5.2 12.4 5.2 6.2 0 12-3.4 15.1-8.7.9-1.5.4-3.4-1.2-4.3-1.5-.9-3.4-.4-4.3 1.2-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.6-1-1.6-1.6-2.7-1.6s-2.2.6-2.7 1.6c-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.6-1-1.6-1.6-2.7-1.6s-2.2.6-2.7 1.6c-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.9-1.5-2.8-2-4.3-1.2-1.5.9-2 2.8-1.2 4.3 3.3 5.4 9.1 8.7 15.3 8.7zM98.8 53.6c-1.5-.9-3.4-.4-4.3 1.2-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.6-1-1.6-1.6-2.7-1.6s-2.2.6-2.7 1.6c-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.6-1-1.6-1.6-2.7-1.6s-2.2.6-2.7 1.6c-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.9-1.5-2.8-2-4.3-1.2-1.5.9-2 2.8-1.2 4.3 3.1 5.4 8.9 8.7 15.2 8.7 4.7 0 9.2-1.9 12.4-5.2 3.2 3.3 7.7 5.2 12.4 5.2s9.2-1.9 12.4-5.2c3.2 3.3 7.7 5.2 12.4 5.2 6.2 0 12-3.4 15.1-8.7.9-1.5.4-3.4-1.1-4.3zM98.8 76.8c-1.5-.9-3.4-.4-4.3 1.2-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.6-1-1.6-1.6-2.7-1.6s-2.2.6-2.7 1.6c-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.6-1-1.6-1.6-2.7-1.6s-2.2.6-2.7 1.6c-2 3.4-5.7 5.6-9.7 5.6s-7.7-2.1-9.7-5.6c-.9-1.5-2.8-2-4.3-1.2-1.5.9-2 2.8-1.2 4.3 3.1 5.4 8.9 8.7 15.2 8.7 4.7 0 9.2-1.9 12.4-5.2 3.2 3.3 7.7 5.2 12.4 5.2s9.2-1.9 12.4-5.2c3.2 3.3 7.7 5.2 12.4 5.2 6.2 0 12-3.4 15.1-8.7.9-1.5.4-3.5-1.1-4.3z"
      />
    </svg>
  )
}

export default SWMMLogo
