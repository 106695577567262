import React from "react"

const RainfallLogo = (props) => {
  return (
    <svg
      id="prefix__Livello_1"
      x={0}
      y={0}
      viewBox="0 0 120 120"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st2{fill:#fff}.prefix__st3{fill:#f8f7f7}"}</style>
      <circle cx={60} cy={60} r={55} fill="#4ca0ae" />
      <path
        d="M88 51.2C86.9 41 78.3 33.1 67.9 33.1c-1.9 0-3.8.3-5.6.8 7.8 2.2 13.7 9 14.5 17.2 4.3 1.7 7.3 5.7 7.3 10.5C84.1 67.9 79 73 72.5 73h11.2c6.3 0 11.5-5 11.5-11.4.1-4.7-2.9-8.7-7.2-10.4z"
        fill="#f0f0f0"
      />
      <path
        className="prefix__st2"
        d="M84.1 61.7c0-4.9-3-9-7.3-10.6-.8-8.3-6.7-14.9-14.4-17.2-3.6 1-6.8 3-9.3 5.7-1.5-.5-3.1-.9-4.8-.9-6.8 0-12.4 5.1-13.1 11.7-6 .5-10.5 5.2-10.5 11.3C24.7 68 29.8 73 36.3 73h36.4c6.3.1 11.4-4.9 11.4-11.3zM82.2 84c0 2.1-1.7 3.8-3.8 3.8-2.1 0-3.8-1.7-3.8-3.8 0-2.1 3.8-6 3.8-6s3.8 3.9 3.8 6"
      />
      <path
        className="prefix__st3"
        d="M78.3 78.1v9.7c2.1 0 3.8-1.7 3.8-3.8.1-2.1-3.8-5.9-3.8-5.9"
      />
      <path
        className="prefix__st2"
        d="M65 84c0 2.1-1.7 3.8-3.8 3.8-2.1 0-3.8-1.7-3.8-3.8 0-2.1 3.8-6 3.8-6s3.8 3.9 3.8 6"
      />
      <path
        className="prefix__st3"
        d="M61.1 78.1v9.7c2.1 0 3.8-1.7 3.8-3.8.1-2.1-3.8-5.9-3.8-5.9"
      />
      <path
        className="prefix__st2"
        d="M47.6 84c0 2.1-1.7 3.8-3.8 3.8-2.1 0-3.8-1.7-3.8-3.8 0-2.1 3.8-6 3.8-6s3.8 3.9 3.8 6"
      />
      <path
        className="prefix__st3"
        d="M43.9 78.1v9.7c2.1 0 3.8-1.7 3.8-3.8-.1-2.1-3.8-5.9-3.8-5.9"
      />
    </svg>
  )
}

export default RainfallLogo
