import React from "react"

const PanizaAcademyLogo = (props) => {
  return (
    <svg
      id="prefix__Livello_1"
      x={0}
      y={0}
      viewBox="0 0 120 120"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#020203}"}</style>
      <circle cx={60} cy={60} r={55} fill="#4da0ae" />
      <path
        className="prefix__st1"
        d="M26.3 45.1c-.5.3-.9.6-1.4 1-.3.3-.5.5-.7.9-.5.7-.8 1.5-.9 2.3v.2h-9.9l-4.9-4.4h17.8zM34.9 49.5H24.2c.2-.7.5-1.4.8-1.9 1-1.5 2.7-2.4 4.6-2.4 2.5-.1 4.7 1.8 5.3 4.3zM39.6 47.3l1.2 2.2h-2.5zM48.5 49.5h-5.2l-2.5-4.4h5.1zM77.2 45.1h7.7v4.4h-7.7zM26.3 56.1h-5.6l-.1-.1-4.8-4.3h7.4v.2c.2.8.5 1.6.9 2.3.2.3.5.6.7.9.6.3 1 .7 1.5 1zM29.5 51.7v4.4c-1.9 0-3.6-1-4.6-2.4-.4-.5-.6-1.3-.8-1.9l5.4-.1zM33.2 58.3l-2.5 4.4h-2.8L25 59.9l-1.9-1.6z"
      />
      <path
        className="prefix__st1"
        d="M23.2 49.5l-.2.9h2v.5h-1.9l.2.9v.2c.2.8.5 1.6.9 2.3.2.3.5.6.7.9v5l-1.8-1.6-2.4-2.2-.1-.4V45.1H25v1c-.3.3-.6.5-.7.9-.5.7-.8 1.5-.9 2.3l-.2.2z"
      />
      <path
        className="prefix__st1"
        d="M24.2 49.5c.2-.7.5-1.4.8-1.9v1.9h-.8zM24.2 51.7h.8v1.9c-.5-.6-.7-1.2-.8-1.9z"
      />
      <path
        className="prefix__st1"
        d="M24.2 51.7c.2.7.5 1.4.8 1.9 1 1.5 2.7 2.4 4.6 2.4 2.7 0 4.9-1.9 5.4-4.4l-10.8.1zm5.3-6.6c-1.9 0-3.6.9-4.6 2.4-.4.5-.6 1.3-.8 1.9h10.7c-.5-2.4-2.7-4.3-5.3-4.3z"
      />
      <path
        className="prefix__st1"
        d="M29.5 45.1c-1.9 0-3.6.9-4.6 2.4v-1.4c.4-.4.8-.7 1.4-1h3.2z"
      />
      <path
        className="prefix__st1"
        d="M25 46.2v1.4c-.4.5-.6 1.3-.8 1.9h-.9v-.2c.2-.8.5-1.6.9-2.3.2-.3.5-.6.8-.8zM25 49.5v.9h-1.9l.1-.9zM25 50.8v.9h-1.8l-.1-.9z"
      />
      <path
        className="prefix__st1"
        d="M25 53.7V55c-.3-.3-.6-.5-.7-.9-.5-.7-.8-1.5-.9-2.3v-.2h.9c0 .8.2 1.4.7 2.1z"
      />
      <path
        className="prefix__st1"
        d="M29.5 56.1h-3.2c-.5-.3-.9-.6-1.4-1v-1.4c1 1.4 2.7 2.4 4.6 2.4zM35 50.6v.2c0 .3 0 .5-.1.9h-5.5c.6 0 1-.4 1.1-.9v-.4c-.1-.5-.5-.9-1.1-.9h5.5c.1.2.1.5.1.9v.2zM52.2 56.1h-.3v-.6zM56 62.7h-1.9v-3.2zM58.5 45.1v17.6l-.6-1.1-2.5-4.4-1.3-2.2v-9.9zM67.5 45.1h4.4v17.6h-4.4zM79.3 45.1v11.2l-.6 1.1-1.9 3.2-1.1 2.1h-.8V45.1zM38.3 58.3l-2.6 4.4h-5l2.5-4.4z"
      />
      <path
        className="prefix__st1"
        d="M41.6 50.8l.4.9-2.4 4.2-1.3 2.4h-5.1l5.1-8.8 1.3-2.2 1.2 2.2.6 1.1zM45.9 45.1l-2.6 4.4-2.5-4.4z"
      />
      <path
        className="prefix__st1"
        d="M56 62.7h-5.1l-2.5-4.4-1.2-2.2-2.6-4.4-1.3-2.2-2.5-4.4h5.1l2.6 4.4 1.1 2.2 2.3 3.8.3.6 1.3 2.2.6 1.2zM96.6 49.5H94l1.3-2.2.2.2zM104.2 49.5h-5.1l-2.3-4.2-.2-.2h5z"
      />
      <path
        className="prefix__st1"
        d="M97.9 51.7l-2.4 4.2-1.5 2.4h-5l5-8.8 1.3-2.2.2.2 1.1 2 .6 1.1.1.2zM101.6 45.1l-2.5 4.4-2.3-4.2-.2-.2z"
      />
      <g>
        <path
          className="prefix__st1"
          d="M111.8 62.7h-5l-2.6-4.4-1.3-2.2-2.6-4.4-1.2-2.2-2.3-4.2-.2-.2h5l2.6 4.4 1.3 2.2 2.2 3.8.2.6 1.3 2.2.7 1.2z"
        />
      </g>
      <path
        className="prefix__st1"
        d="M86.8 45.1l-2.1 3.7h-.1l-.4.7h-3.7v-4.4zM94 58.3l-.3.4-1 1.9-.8 1.4-.4.7h-6.7l2.1-3.8.3-.6z"
      />
      <g>
        <path
          className="prefix__st1"
          d="M93.4 45.1l-2.6 4.4-1.2 2.2-2.6 4.4-1.2 2.2-2.6 4.4h-5l1.8-3.3.5-.9.2-.2 1.3-2.2.3-.6 2.2-3.8 1.3-2.2 2.5-4.4z"
        />
        <path d="M80.5 62.7h.6-.6zm0-4.4v.3l.2-.3h-.2z" fill="#fff" />
        <path
          className="prefix__st1"
          d="M88.3 45.1l-2.5 4.4h-1.6l.4-.7h.1v-.1l2.1-3.6zM87.3 58.3l-.3.6-.1.1-2.1 3.7h-3.6 2l2.6-4.4z"
        />
      </g>
      <path
        className="prefix__st1"
        d="M70.7 62.7h-5l-2.6-4.4-1.3-2.2-2.4-4.4-.9-1.4-.4-.8-2.6-4.4h5.1l2.5 4.4 1.3 2.2 2.6 4.4.5 1 .7 1.2z"
      />
      <g>
        <path
          className="prefix__st1"
          d="M47.5 68.7v3.8l-.2-.3-.7-1.3-.4-.6v-2.8h1.2v.1c-.1.3-.2.5-.3.6-.1.1-.1.2-.2.3-.1.1-.3.2-.4.3h1zM42.8 69.1l.1.2-.7 1.2-.4.7h-1.5l1.5-2.5.3-.6.4.6.2.3v.1zM46.8 72.5h-1.4l-.7-1.3-.4-.7-.7-1.2-.4-.6-.7-1.3H44l.7 1.3.4.6.6 1.1.1.1.3.7.2.3zM80 67.4l-.7 1.3-.4.6-.4.6-.3.6-.4.7-.8 1.3h-1.3l.4-1 .2-.3.4-.7.1-.1.2-.5.4-.6.4-.6.7-1.3z"
        />
        <path
          className="prefix__st1"
          d="M78.5 69.9l-.3.6-.4.7-.4-.7-.4-.6-.3-.6-.4-.6-.6-1.3H77l.8 1.3.4.6zM54 69.1l.2.2-.6 1.2-.5.7h-1.4l1.4-2.5.4-.6.4.6.1.3zM58.2 72.5h-1.4l-.7-1.3-.4-.7-.7-1.2-.4-.6-.7-1.3h1.4l.8 1.3.3.6.7 1.1.1.1.2.7.3.3zM47.5 67.4v1.3h-.9c.2-.1.3-.2.4-.3.1-.1.2-.2.2-.3.2-.2.2-.4.3-.7zM64.6 68.7v3.8l-.2-.3-.7-1.4-.4-.6v-2.8h1.3v.1c-.1.3-.2.5-.3.6-.1.1-.1.2-.2.3-.1.1-.3.2-.4.3h.9z"
        />
        <path
          className="prefix__st1"
          d="M64.6 67.4v1.3h-.9c.2-.1.3-.2.4-.3.1-.1.2-.2.2-.3.1-.2.2-.4.3-.7zM59.2 68.7v3.8l-.2-.3-.7-1.3-.4-.6v-2.8h1.2v.1c-.1.3-.2.5-.3.6-.1.1-.1.2-.2.3-.1.1-.3.2-.4.3h1z"
        />
        <path
          className="prefix__st1"
          d="M59.2 67.4v1.3h-.9c.2-.1.3-.2.4-.3s.2-.2.2-.3c.1-.2.2-.4.3-.7zM51.7 68.7h-4.2v-1.3h2.8zM74.5 68.1v.6h-4.6v-1.3h3.7zM68.8 68.7h-4.2v-1.3h2.7zM68.8 72.4h-4.2v-1.2h2.7zM68.8 70.5h-4.2v-1.2h2.7zM61.5 68.7h-2.3v-1.3h1.4zM51.7 72.5h-4.2v-1.3h2.8zM62.9 72.5h-3.7v-1.3h2.5z"
        />
        <path
          className="prefix__st1"
          d="M63.8 72.5h-1.4l-.8-1.3-.3-.7-.8-1.2-.3-.6-.7-1.3h1.4l.7 1.3.4.6.7 1.1.1.1.3.7.2.3zM70.7 67.4v3.2l-.2.3-.5.9-.4.6h-.2v-5zM74.5 67.4v5h-.2l-.3-.6-.5-.9-.2-.3v-3.2z"
        />
      </g>
    </svg>
  )
}

export default PanizaAcademyLogo
