import React from "react"

const WritingLogo = (props) => {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 120 120"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st4{fill:#ae8a50}.prefix__st5{fill:#dbbd40}.prefix__st6{fill:#f0d359}"
        }
      </style>
      <circle cx={60} cy={60} r={55} fill="#49a0ae" />
      <path
        d="M76.2 76.2l13-44c-.1-.8-.8-1.5-1.5-1.5l-44 13c-.4 0-2 1.4-2.2 1.8-.3.2-12.4 27.5-12.4 27.5 0 .7 17 17.7 17.7 17.7 0 0 27.4-11.9 27.6-12.2.4-.3 1.8-1.9 1.8-2.3z"
        fill="#edc92c"
      />
      <path
        d="M88.8 31.1c-.2-.2-.6-.5-1-.5l-44 13c-.4 0-2 1.4-2.2 1.8-.3.2-12.4 27.5-12.4 27.5 0 .3 4.4 4.9 8.6 9.1l51-50.9z"
        fill="#f1d250"
      />
      <path
        d="M44.8 92.1l-8.5-8.5L21 98.8c3.2 3.2 6.8 6 10.7 8.4l13-13c.7-.6.7-1.5.1-2.1z"
        fill="#9b7842"
      />
      <path
        className="prefix__st4"
        d="M36.2 83.6L27.6 75l8.6 8.6zM36.2 83.6L27.6 75c-.6-.6-1.5-.6-2.1 0l-13 13c2.5 4 5.3 7.6 8.5 10.8l15.2-15.2z"
      />
      <path
        className="prefix__st5"
        d="M53.8 69c-.2.2-1.5-.7-1.8-1-.3-.3-1.2-1.6-1-1.8l37.3-35.6c.2-.2.7-.2 1 .1.3.3.4.7.1 1L53.8 69z"
      />
      <path
        className="prefix__st5"
        d="M54.6 70.7c-1.8 1.8-4.4 2-5.9.6-1.5-1.5-1.2-4.1.6-5.9 1.8-1.8 4.4-2 5.9-.6 1.4 1.5 1.1 4.1-.6 5.9z"
      />
      <path
        className="prefix__st6"
        d="M54 70.2c-1.4 1.4-3.5 1.6-4.7.5-1.2-1.2-1-3.3.5-4.7 1.4-1.4 3.5-1.6 4.7-.5 1.1 1.2.9 3.3-.5 4.7z"
      />
      <path
        className="prefix__st6"
        d="M89.5 31.1l-.2-.4c-.2-.2-.4-.2-.4-.2L51.7 66.8c-.2.2.1 1 .3 1.1.2.2.9.5 1.1.3l36.4-37.1z"
      />
      <path
        d="M89.2 30.7c-.2-.2-.4-.2-.4-.2L53.6 64.9c-1.2-.4-2.7-.1-3.8 1-1.4 1.4-1.6 3.5-.5 4.7l39.9-39.9z"
        fill="#f8de71"
      />
      <path
        d="M46.9 93.9c-.6.6-1.6.6-2.3 0L25.9 75.2c-.6-.6-.6-1.6 0-2.3l1.3-1.3c.6-.6 1.6-.6 2.3 0l18.7 18.7c.6.6.6 1.6 0 2.3l-1.3 1.3z"
        fill="#3d969f"
      />
      <path
        d="M35.3 84.6l-9.4-9.4c-.6-.6-.6-1.6 0-2.3l1.3-1.3c.6-.6 1.6-.6 2.3 0l9.4 9.4-3.6 3.6z"
        fill="#4aa0a5"
      />
    </svg>
  )
}

export default WritingLogo
